
@import "partials/variables.scss";
@import "partials/cubeportfolio.scss";
@import "partials/mixins.scss";
@import "partials/global.scss";
@import "partials/navbar.scss";
@import "partials/masthead.scss";
@import "partials/download.scss";
@import "partials/features.scss";
@import "partials/cta.scss";
@import "partials/contact.scss";
@import "partials/footer.scss";
@import "partials/bootstrap-overrides.scss";
@import "partials/simplelightboxgallery.scss";
a
{
	color:inherit;
	&:hover
	{
		color:inherit;
		text-decoration: none;
	}
}
p
{
	font-family:"Proxima";
	font-weight: lighter;
}
.course_wrapper
{
	width:100%;
	max-height:450px;
	overflow: hidden;
	position:relative;	
	margin-bottom:20px;
	border-radius:10px;
	transition:margin 0.2s ease-in;
	&:hover
	{
		margin-top:-10px;
	}
	img{
		width:100%;
	}
	h4
	{
		position: absolute;
		bottom:10px;
		font-size:1.05em;
		font-weight: 500;
		color:#fff;
		text-align: center;
	    width: 100%;
	    word-wrap: break-word;	    
	    z-index:8;
	    font-family:$poppins;
	    padding:0 8px;
	}
	.learn_more
	{
		background-color:$dark-blue;
		position: absolute;
		width:100%;
		bottom:-45px;
		text-align:center;
		padding:15px;
		transition:all 0.5s ease;
		z-index:9;
		a{
			color:#fff;
			font-size:1.15em;
			&:hover
			{
				text-decoration: none;
			}
		}
	}

}
.course_wrapper:after{
	content:"";
	display:block;
	width:100%;
	height: 100%;
	background: -webkit-linear-gradient(to top,rgba(255,255,255,0.02), #000); /* For Safari 5.1 to 6.0 */    
    background:linear-gradient(to bottom,rgba(255,255,255,0.02),#000);/* Standard syntax (must be last) */
	position: absolute;
	bottom:0;
	left:0;
	z-index:1;
}


.contact_section
{
	background:#222;
}
.inverse 
{
	h2
	{
		color:#fff;
	}
	p
	{
		color:rgba(#fff,0.6);
		margin-top:-15px;
	}
	hr
	{
		margin-bottom: 50px;
		border-color:orange;
	}
	
}

/////////////////subheader
#subheader
{
	background-size:cover;
	.subheader-overlay
	{
		padding:150px 0 110px;
		background-color:#1b1e21bd;
	}
	h2
	{
		color:#fff;
		text-align: center;
		font-size:55px;
	}
	h4
	{
		color:rgba(#fff,0.70);
		text-align: center;

	}
}
@media screen and (max-width:760px)
{
#subheader
	{
		.subheader-overlay
		{
			padding:150px 0 110px;
			background-color:#1b1e21bd;
		}
		h2
		{
			color:#fff;
			text-align: center;
			font-size:30px;
		}
	}
}


//////////////////// Career page
#career_body
{
	background:#f1f1f1;
	[class*="col"]
	{
		
		margin-bottom:30px;
		
	}
}
.career_post
{
	background-color:#fff;
	padding:25px;
	border-radius:10px;
	height:100%;
	margin-bottom: 30px;
	&:hover
	{
		box-shadow: 0px 10px 8px 0px rgba(#000,0.1);
	}
	span
	{
		background-color:orange;
		display: inline-block;
		padding:1px 10px;
		font-size:14px;
		border-radius:5px;
		color:#fff;
		font-family: arial;
	}
	h6
	{
		font-size:26px;
		font-weight: 600;
		padding-top:20px;
	}
}
.career_list
{
	list-style: none;
	padding:0;
	i
	{
		display: inline-block;
		padding-right:20px;
	}
}



//////////////// Service Section
#services
{
	background:#e8e8e8;
	
}
@media screen and (max-width:845px)
{
	#services
	{
		
		div[class*="col-6"]:nth-child(odd)
		{
			padding-right:7.5px;
		}
		div[class*="col-6"]:nth-child(even)
		{
			padding-left:7.5px;
		}
	}
}


////////////// MODALS
.modal
{
	background:rgba(#000,0.89);
}
.course_modal_content
{
	padding:30px;
	border:none;
	img.modal_banner
	{
		max-width: 700px;
		height:auto;
	}
	p
	{
		font-size: 0.9em;
	}
	
}



///////////////VENDORS
.embed-container { 
		position: relative; 
		padding-bottom: 56.25%;
		overflow: hidden;
		max-width: 100%;
		height: auto;
	} 

	.embed-container iframe,
	.embed-container object,
	.embed-container embed { 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

/////////////// Project content
.project_content
{
	margin-top:30px;
	p
	{
		font-size:15px;
		&:nth-of-type(1)
		{
			font-weight: 700;
			
		}
	}
	hr
	{
		margin-top:10px;
		margin-bottom: 20px;
		border-color:orange;
	}
}

.page-link
{
	color:#fff;
	background-color:#333;
	font-size:13px;

	&:hover
	{
		color:orange;
		background-color:#333;
	}
}

.contact_information
{
	color:#fff;
	padding-bottom:50px;
	font-size:11px;
	text-align: center;
	p
	{
		margin-bottom: 0;
		font-size:12.5px;
		color:rgba(#fff,0.5);
		i
		{
			color:orange;
			padding-right: 10px;
		}
	}
}
.list-inline
{
	margin-top:-5px;
}
#main_banner
{
	background-size:cover;
	background-position: center center;
	.main_banner_overlay
	{
		padding:120px 0;
		background-color:rgba(#000,0.8);
	}
	.main_banner_description
	{
		padding:15px;
		text-align: center;
		h2
		{
			text-align: center;
			font-size:50px;
			color:#fff;
		}
		p
		{
			color:#fff;
			text-align: center;
			font-size:15px;
		}
		a
		{
			text-align: center;
			display: inline-block;
			padding:10px 25px;
			border-radius:0px;
			background-color: orange;
			color:#fff;
			font-size:15px;
			text-transform: uppercase;
			color:#000;
			margin-top: 50px;
			font-weight: 600;
		}
	}
}
@media screen and (max-width: 768px)
{
	#main_banner
		{
			.main_banner_overlay
			{
				padding:50px 0;				
			}
			.main_banner_description
			{
				padding:15px;
				text-align: center;
				h2
				{					
					font-size:30px;					
				}
				p
				{					
					font-size:13px;
				}				
			}
		}
}

/////////////////// Testimonail
#testimonial
{
	background:#062c54;
	padding:50px;
}
.testimonail_wrapper
{
	.testimonial_image
	{
		img
		{
			width:100%;
			position:relative;
			top:100px;
		}
	}
	.testimonial_body
	{
		p
		{
			color:#fff;
		}
	}
}