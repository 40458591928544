// Global styling for this template
html,
body {
    width: 100%;
    height: 100%;
}

body {
    @include body-font;
}

a {
    color: $theme-primary;
    @include transition-all;
    &:hover,
    &:focus {
        color: darken($theme-primary, 10%);
    }
}

hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: fade-out($gray-darker, .9);
}

hr.light {
    border-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include heading-font;
}

p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
}

section {
    padding: 100px 0;
    h2 {
        font-size: 50px;
    }
}
@media screen and (max-width: 768px)
{
    section
    {
        padding:50px 0;
    }
}
