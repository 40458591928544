// Styling for the features section
section.features {
   
    .device-container,
    .feature-item {
        max-width: 325px;
        margin: 0 auto;
    }
    .device-container {
        margin-bottom: 100px;
        @media(min-width: 992px) {
            margin-bottom: 0;
        }
    }
    .feature-item {
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        h3 {
            font-size: 30px;
        }
        i {
            font-size: 80px;
            display: block;
            margin-bottom: 15px;
            background: -webkit-linear-gradient(to left, $theme-secondary, $theme-tertiary);
            background: linear-gradient(to left, $theme-secondary, $theme-tertiary);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

 .section-heading {
        margin-bottom: 100px;
        h2 {
            margin-top: 0;
            font-family: "Poppins";
            font-weight: 500;
            font-size:45px;
        }
        p {
            margin-bottom: 0;
            margin-top:-10px;
        }
        hr
        {
            border-color:orange;
        }
    }


#sub_header
{
    background:#333;
    padding:70px 0 50px 0;
    h3
    {
        color:#fff;
        font-family: "Poppins";
        font-weight: 700;
        font-size:40px;
    }
    p
    {
        margin:0;
        color:orange;
        margin-top:-15px;
    }

}


    @media screen and (max-width:768px)
    {
        .section-heading
        {
            margin-bottom: 50px;
            h2
            {
                font-size: 35px;
            }
        }
        #sub_header
        {
            
            h3
            {
                color:#fff;
                font-family: "Poppins";
                font-weight: 700;
                font-size:30px;
            }
            p
            {
                margin:0;
                color:orange;
                margin-top:-15px;
            }

        }
    }

////////////////COURSE PAGE
#course
{
    background:#e6e6e6;
    padding-top:30px;
}
.card
{
    border:0;
    margin-top:15px;
    padding:25px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.07);
    p
    {
        font-size:15px;
        line-height: 22px;
    }
    h2
    {
        font-family: "Poppins";
        font-size:1.3em;
        font-weight: 500;
        margin-bottom: 0px;
        letter-spacing: 0;
        color:#000;
        text-transform: uppercase;
    }
    hr
    {
        max-width:100%;
        margin:10px 0;
        border-color:orange;
    }
    ul 
    {
        padding-left: 20px;
         li
            {
                font-size:15px;
                line-height:22px;
                margin-bottom: 6px;
            }
    }
   
}
.course_short
{
    background-color:#03457b;
    padding:15px;
    ul
    {
        display:flex;
        padding:0;
        margin:0;
        list-style:none;
        li
        {
            padding:0 15px;
            color:rgba(#fff,0.5);
            border-right:1px solid rgba(#fff,0.3);
            &:last-child
            {
                border:none;
            }
            span
            {
                color:#fff;
            }
        }
    }
}

///////////////////////// Related course
.related_course_list

{
    padding-left: 0px !important;
    .media
    {
        border-bottom: 1px solid rgba(#000,0.2);
        padding:8px 0;
        img
        {
            width:55px;
        }
        .media-body
        {
            h5
            {
                font-family:"Poppins";
                font-weight: 500;
                font-size:15.5px;
            }
            p
            {
                color:rgba(#000,0.6);
                margin-bottom: 0;
            }
        }
    }

}

