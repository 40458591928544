// Styling for the download section
section.contact {
    text-align: center;
    h2 {
        margin-top: 0;
        margin-bottom: 25px;
        i {
            color: $brand-google-plus;
        }
    }
    ul.list-social {
        margin-bottom: 0;
        li {
            a {
                font-size: 40px;
                line-height: 80px;
                display: block;
                width: 80px;
                height: 80px;
                color: white;
                border-radius: 100%;
            }
            &.social-twitter {
                a {
                    background-color: $brand-twitter;
                    &:hover {
                        background-color: darken($brand-twitter, 5%);
                    }
                }
            }
            &.social-facebook {
                a {
                    background-color: $brand-facebook;
                    &:hover {
                        background-color: darken($brand-facebook, 5%);
                    }
                }
            }
            &.social-google-plus {
                a {
                    background-color: $brand-google-plus;
                    &:hover {
                        background-color: darken($brand-google-plus, 5%);
                    }
                }
            }
        }
    }
}


////////////////////////Forms
.form-control
{
    background:rgba(#fff,0.2);
    border:none;
    color:#fff;
}
.form-control:focus {
    color: #000;
    background-color: #ffffffd1;
    border-color: #ffffff00;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
.form-control::placeholder
{
    color:rgba(#000,0.3);
}

.btn-contact
{
    background:orange;
    color:#222;
    border:0;
    &:hover
    {
        color:#fff;
    }
}